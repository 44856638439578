$.lui("layout","#layout_header", function(selector) {
    let header_head = selector.find(".wrp_header_head");
    let header_body = selector.find(".wrp_header_body");

    if (! $("#layout_nav").length) {
        selector.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_head"></div><div class="wrp_nav_content"></div></nav>');

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').slideAndSwipe();
        });

        let layout_nav = $(document).find("#layout_nav"),
            logo = header_head.find(".elm_header_logo").clone(),
            nav = header_body.find(".elm_header_nav").clone(),
            contact = header_head.find(".col--contact").clone().removeClass().addClass("elm_contact"),
            controls = header_head.find(".col--controls").clone().find(".col--basket").removeAttr("id").empty().closest(".col--controls").removeClass().addClass("elm_controls");

        layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(controls);
        layout_nav.find(".wrp_nav_content").append(nav);
        layout_nav.find(".wrp_nav_content").append(contact);
    }

    $.importScript(cdnjs.headroom, function(){
        let headroom = new Headroom(selector[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    });

    header_body.on("mouseenter",".elm_nav_sub .elm_nav_item",function () {
        let el = $(this),
            img = el.data("image");

        el.closest(".elm_nav_sub").find("img").attr("src",img);
    })
});