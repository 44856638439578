$.lui("comp", ".comp_brands_carousel", function(selector) {
    $.lib_flickity(function(){
        selector.each(function(){
            let el = selector.find("[data-carousel]");
            if(el.length) {
                el.flickity({
                    groupCells: "25%",
                    cellAlign: "left",
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: true,
                    autoPlay: false,
                    pageDots: false,
                    wrapAround: true,
                    contain: true,
                    prevNextButtons: true
                });
            }
        });
    });
});