$.lui("comp", ".comp_products_detail", function(selector) {
    $.lib_flickity(function(){
        let slider = selector.find("[data-slider]").flickity({
            groupCells: '25%',
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            wrapAround: true,
            prevNextButtons: false,
            percentPosition: true,
            imagesLoaded: false
        });

        slider.on( 'select.flickity', function( event, index ) {
            selector.find("[data-flickity-item]").removeClass("state--active");
            selector.find(".elm_gallery_thumbs > .col").eq(index).find("[data-flickity-item]").addClass("state--active");
        });

        selector.on("click", "[data-flickity-item]", function(){
            let elm = $(this);
            let index = elm.closest(".col").index();

            elm.closest(".elm_gallery_thumbs").find("[data-flickity-item]").removeClass("state--active");
            elm.addClass("state--active");

            slider.flickity( 'select', index );
        });
    });

    selector.on("click", "[data-gallery-more]", function(){
        selector.find(".elm_comp_gallery").find(".elm_gallery_slider").find(".col").eq(5).find("a").trigger("click");
    });

    if(selector.find("[data-print]").length) {
        $.importScript(cdnjs.printjs,function () {
            selector.on("click","[data-print]",function (e) {
                e.preventDefault();
                let link = $(this).prop("href");
                printJS(link);
            })
        });
    }

    selector.on('change','[data-product-variant]',function() {

        let variantId;
        if($(this).is('select')) {
            variantId = $(this).val();
        } else {
            variantId = $('[data-product-variant]:checked').val();
        }

        let url = $(this).attr('data-ajax-link');

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json',
            data: {
                variantId: variantId
            },
            cache: false
        }).done(function (payload) {
            fn_ajaxHandler(payload, function () {
                ScrollReveal().sync();
                let name = $('[data-heading-for-form]').text();
                // console.log(name);
                $('[data-form-produkt]').val(name);
                window.lui.lib["lib_wsw"]("lib_wsw", $(".part_ui_wsw"))
            });
        });

    });
});
