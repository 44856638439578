var reInitGCaptcha;

(function($) {

    doc.on('click','[data-change-currency]',function(e) {
        e.preventDefault();
        let currencyCode = $(this).attr('data-change-currency');

        /*location.reload(true);*/

        $.ajax({
            method: 'post',
            url: '?do=changeCurrency',
            data: {
                code: currencyCode
            },
            dataType: "json",
            success: function (payload) {
                console.log('ajax success');
                location.reload();
            }
        });
    });

    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6Lc2mY8UAAAAAH4BlFBSIiy630sWEh2kvwYP42U7").done(function () {
            grecaptcha.ready(function () {

                $('form[data-recaptcha]').each(function() {
                    let form = $(this);
                    let type = $(this).attr('data-recaptcha');

                    grecaptcha.execute('6Lc2mY8UAAAAAH4BlFBSIiy630sWEh2kvwYP42U7', {action: type})
                        .then(function (token) {
                            form.find('[data-g-token]').val(token);
                        });

                });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
    }


    if (typeof Swup !== "undefined") {
        const swup = new Swup({
            elements: ['#layout_main', '#layout_header', '#layout_footer'],
            cache: false
        });

        swup.on('clickLink', function () {
            $(".ssm-nav-visible").removeClass("ssm-nav-visible");
            $(".ssm-overlay").fadeOut(300);
            $('html').css('overflow','visible');
            if ($(".lib--dialog").length) {
                nl_lib_dialog.close();
            }
        });


        swup.on('contentReplaced', function () {
            swup.options.elements.forEach((selector) => {
                Object.keys(window.lui.layout).forEach(function(key) {
                    if ($(key).length) {
                        window.lui.layout[key]("layout",$(key))
                    }
                });
                Object.keys(window.lui.comp).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.comp[key]("comp",$(key))
                    }
                });
                Object.keys(window.lui.part).forEach(function(key) {
                    if ($(selector).find(key).length) {
                        window.lui.part[key]("part",$(key))
                    }
                });
                Object.keys(window.lui.lib).forEach(function(key) {
                    let s = window.lui.lib[key].selector;
                    if ($(selector).find(s).length) {
                        window.lui.lib[key](key,$(selector).find(s))
                    }
                });
            });

            if (body.hasClass("body--preload")) {
                cssLoaded(function(){
                    body.removeClass("body--preload");
                    setTimeout(function () {
                        body.addClass("body--loaded");
                    }, 300);
                });
            }

            if ($('form[data-recaptcha]').length) {
                reInitGCaptcha();
            }

            if (typeof fbq !== "undefined") {
                fbq('track', 'PageView');
            }

            if (typeof gtag !== "undefined") {
                let configs = [];
                window.dataLayer.forEach(function(config){
                    if (config[0] === "config") {
                        if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                            configs.push(config[1]);
                            gtag('config', config[1], {
                                'page_title': document.title,
                                'page_path': window.location.pathname + window.location.search
                            });
                        }
                    }
                });
            }

            if (typeof window.dataLayer !== "undefined") {
                window.dataLayer.push({
                    'event': 'VirtualPageview',
                    'virtualPageURL': window.location.pathname + window.location.search,
                    'virtualPageTitle': document.title
                });
            }

            if (window.rc && window.rc.retargetingHit) {
                window.rc.retargetingHit(retargetingConf);
            }

            if (typeof window.lui_cookiesconsent !== "undefined") {
                window.lui_cookiesconsent.fn_cookieconsent_init()
            }

        });
    }

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    nl_lib_dialog.init(function(){
        Object.keys(window.lui.lib).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s).length) {
                window.lui.lib[key](key,dialog_selector.find(s))
            }
        });

        Object.keys(window.lui.part).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");

            if (dialog_selector.find($(key)).length) {
                window.lui.part[key]("part",dialog_selector.find($(key)))
            }
        });
    });

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().parallax();
        });
    });

    $.lui("lib_scrollreveal", "[data-reveal]", function() {
        $.lib_scrollreveal();
    });

    $.lui("lib_ripple", ".part_ui_link, .part_ui_btn, .part_ui_checkbox, .part_ui_radio", function(selector) {
        selector.nl_lib_ripple();
    });

    $.lui("lib_switch", "[data-lib-switch]", function(selector) {
        selector.nl_lib_switch();
    });

    $.lui("lib_autosize", "textarea", function(selector) {
        selector.nl_lib_autosize();
    });

    $.lui("lib_gallery", "[data-lib-lightgallery]", function(selector) {
            if ($(selector).find(".lib--lightgallery-item").length) {
                $.importScript(cdnjs.lightgallery, function(){
                    $(selector).each(function(){
                        $(this).lightGallery({
                            thumbnail: true,
                            selector: ".lib--lightgallery-item",
                            exThumbImage: 'data-exthumbimage',
                            fullScreen: false,
                            zoom: true,
                            actualSize: false,
                            hash: false,
                            download: true,
                            autoplay: false,
                            autoplayControls: false,
                        }).data("lightGallery");
                    });
                });
            }
    });

    doc.on("click", "[data-basket-add]", function (e) {
        let header = $("#layout_header");

        if (header.hasClass("headroom--unpinned")) {
            header.removeClass("headroom--unpinned").addClass("headroom--pinned");
        }

        body.append('<div class="layout_basket_circle"></div>');

        let basket_circle = $(".layout_basket_circle");
        let basket_target = header.find(".col--basket").find("span");
        basket_circle.css({
            "top": e.clientY + "px",
            "left": e.clientX + "px"
        });

        basket_circle.animate({
            top: basket_target.position().top + 12,
            left: basket_target.position().left + basket_target.outerWidth() + 8,
            width: 14,
            height: 14,
            opacity: 0.1
        }, 800, function () {
            basket_circle.remove();
        });
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let $el = $(this);

            $.ajax({
                url: $el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload,function() {
                        if ($('form[data-recaptcha]').length) {
                            reInitGCaptcha();
                        }
                    });
                }
            });
        });

        doc.on('click', 'a[data-component-ajax],button[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();
})(jQuery);
