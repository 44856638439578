$.lui("comp", ".comp_products_carousel", function(selector) {
    $.lib_flickity(function(){
        selector.each(function(){
            let el = $(this);

            el.on("click",".part_tabs .part_ui_btn",function () {
                let btn = $(this);
                btn.closest(".part_tabs").attr("data-active", btn.closest(".col").index());
            });

            let carousel = el.find("[data-carousel]");
            if(carousel.length && carousel.children().length > 1) {
                carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: true,
                    autoPlay: false,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    prevNextButtons: false,
                    adaptiveHeight: true
                });
                let flkty = carousel.data("flickity");

                el.on("click",".flickity-prev-next-button",function () {
                    let btn = $(this);
                    if(btn.is(".next")) {
                        carousel.flickity("next");
                    }
                    if(btn.is(".previous")) {
                        carousel.flickity("previous");
                    }
                })
            }
            else {
                carousel.children().first().addClass("is-selected");
                carousel.closest(".wrp_comp_body").find(".flickity-button").remove();
            }
        });
    });
});