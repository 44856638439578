let priceRangeBackToDefaults;
let doCatalogFilter;

$.lui("comp", ".comp_products_category", function(selector) {

    priceRangeBackToDefaults = function()
    {
        let defaultMin = $('input[name="priceMinDefault"]').val();
        let defaultMax = $('input[name="priceMaxDefault"]').val();
        $('[data-range-input="min"]').val(defaultMin).change();
        $('[data-range-input="max"]').val(defaultMax).change();
    };

    doCatalogFilter = function(redrawFiltersOnly,page,pagingType,fromSession)
    {

        let filterValues = {};
        $('[data-catalog-filter]').each(function() {
            let name = $(this).attr('name');
            let value = $(this).val();

            filterValues[name] = value;
        });

        $('[data-catalog-filter-check]').each(function() {
            if($(this).prop('checked') === true) {

                let name = $(this).attr('data-name');
                let value = $(this).val();
                if(typeof filterValues[name] === 'undefined') {
                    filterValues[name] = [];
                }
                filterValues[name].push(value);
            }
        });

        let sortType = $('select[data-sort]').val();

        let searchQuery = null;
        if($('.comp_products_category').is('[data-search-query]')) {
            searchQuery = $('.comp_products_category').attr('data-search-query');
        }

        $.ajax({
            url: selector.attr('data-ajax-link'),
            method: 'post',
            dataType: 'json',
            cache: false,
            data: {
                filterData: filterValues,
                onlyFilters: (redrawFiltersOnly ? 1 : 0),
                page: (page ? page : 0),
                fromSession: (fromSession ? fromSession : 0),
                pagingType: (pagingType ? pagingType : 'default'),
                sortType: sortType,
                searchQuery: searchQuery
            }
        }).done(function (payload) {
            fn_ajaxHandler(payload,function() {
                ScrollReveal().sync();
            });
        });
    };

    //paging
    selector.on('click','a[data-do-filter],button[data-do-filter]',function(e) {
        e.preventDefault();

        let pagingType = 'default';
        if($(this).is('[data-append]')) {
            pagingType = 'append';
        }

        let page = 1;
        if($(this).is('[data-page]')) {
            page = $(this).attr('data-page');
        }

        doCatalogFilter(0,page,pagingType);
    });

    //sorting
    selector.on('change','select[data-sort]',function() {
        console.log('trigger sorting');
        doCatalogFilter(0,1);
    });


    $.lib_flickity(function(){
        let el = selector.find("[data-carousel-top]");
        if(el.length) {
            let autoplay = el.data("carousel-top");
            el.flickity({
                imagesLoaded: false,
                percentPosition: false,
                setGallerySize: false,
                pageDots: false,
                wrapAround: true,
                contain: true,
                prevNextButtons: true,
                autoPlay: autoplay
            });
        }
    });

});