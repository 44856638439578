$.lui("part", ".part_filter", function(selector) {
    let range = selector.find(".part_ui_range");
    if (range.length) {
        range.each(function () {
            let elm = $(this);
            $.importScript(cdnjs.autoNumeric, function () {
                $.importScript(cdnjs.noui, function () {
                    elm.lib_noui({
                        elm_min: elm.find(`[data-range-input="min"]`),
                        elm_max: elm.find(`[data-range-input="max"]`),
                        elm_min_hidden: elm.find(`[data-range-input="min"]`).next(),
                        elm_max_hidden: elm.find(`[data-range-input="max"]`).next(),
                        unit: elm.data("unit")
                    });

                    elm.on("change", `[data-range-input="min"]`, function () {
                        let min = $(this).val();
                        let max = elm.find(`[data-range-input="max"]`).val();
                    });
                    elm.on("change", `[data-range-input="max"]`, function () {
                        let min = elm.find(`[data-range-input="min"]`).val();
                        let max = $(this).val();
                    });
                });
            });
        });
    }

    selector.on("click", "[data-toggle]", function() {
        let elm = $(this);
        if (!elm.hasClass("state--active")) {
            elm.addClass("state--active");
            elm.next().slideDown(300);
        } else {
            elm.removeClass("state--active");
            elm.next().slideUp(300);
        }
    });

    selector.find('[data-toggle]').each(function() {
        let elm = $(this);
        if(elm.next().find('input[type="checkbox"]:checked').length) {
            elm.addClass("state--active");
            elm.next().show();
        }
    });

    selector.on('change','[data-do-filter-change]',function() {
        doCatalogFilter(0,1);
    });

    selector.on('change','[data-catalog-filter-check]',function() {
        doCatalogFilter(0,1);
    });

    selector.on('click','[data-cancel-filters]',function(e) {
        e.preventDefault();

        $('select[data-sort]').val('').parent().addClass('state--placeholder');
        $('[data-catalog-filter-check]').prop('checked',false);
        priceRangeBackToDefaults();

        doCatalogFilter(0,1);
    });

});