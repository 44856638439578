$.lui("comp", ".comp_package_basket", function(selector) {
    selector.on("change", "[data-toggle] input", function() {
        let row = $(this).closest("[data-toggle]").next(".elm_form_row");
        if ($(this).prop("checked") === true) {
            row.slideDown(300);
        } else {
            row.slideUp(300);
        }
    });

    selector.on('change', '[data-change-country]', function () {
        var elm = $(this);

        $.ajax({
            url: elm.data('change-country'),
            method: 'post',
            data: {countryId: elm.val()},
            dataType: 'json'
        }).done(function (payload) {
            //location.reload(true);
            fn_ajaxHandler(payload);
        });
    });

    selector.on('change','input[type="number"][data-ajax-link]',function() {
        let ajaxLink = $(this).attr('data-ajax-link');
        let count = $(this).val();

        $.ajax({
            url: ajaxLink,
            data: {count: count},
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });

    selector.on('click','[data-add-voucher]',function() {

        let url = $(this).attr('data-ajax-link');
        let code = $('input[data-voucher-code]').val();

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json',
            cache: false,
            data: {
                code: code
            }
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });

    });

    selector.on('change','[data-service-item]',function() {
        let ajaxLink = $(this).attr('data-ajax-link');

        $.ajax({
            url: ajaxLink,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });
});