$.lui("comp", ".comp_visual", function(selector) {
    $.lib_flickity(function(){
        selector.each(function(){
            let el = selector.find("[data-visual]");
            if(el.length) {
                let autoplay = el.data("visual");
                el.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    prevNextButtons: true,
                    autoPlay: autoplay,
                    lazyLoad: true
                });
            }
        });
    });
});