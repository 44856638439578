$.lui("comp", ".comp_products_related", function(selector) {
    $.lib_flickity(function(){
        selector.each(function(){
            let el = $(this);

            let carousel = el.find("[data-carousel]");
            if(carousel.length) {
                carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: true,
                    autoPlay: false,
                    pageDots: false,
                    wrapAround: true,
                    contain: true,
                    prevNextButtons: true,
                    cellAlign: "left",
                    watchCSS: carousel.children().length < 4
                });
            }
        });
    });
});